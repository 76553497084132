<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Contact us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Customer Service</h2>
          <p>Get in touch with our customer service team</p>
          <ul>
            <li><a href="https://info.tradewindsnews.com/tw-customer-service-form">Contact us</a></li>
            <li>
              <div>Call Us:</div>
              <div class="pl-4">London: +44 207 645 2300 ,<br> Oslo: +47 2200 1200 ,<br> Stamford: +1 203 324 2994 ,<br> Singapore: +65 3165 0940</div>
            </li>
          </ul>
          <p>
            Editorial enquiries: <a href="mailto:news@tradewindsnews.com">news@tradewindsnews.com</a><br>
            Commercial enquiries: <a href="mailto:sales@tradewindsnews.com">sales@tradewindsnews.com</a><br>
            Privacy enquiries: <a href="mailto:nhstglobal.privacy.officer@nhst.com">nhstglobal.privacy.officer@nhst.com</a><br>
            Security enquiries: <a href="mailto:security@nhst.no">security@nhst.no</a>
          </p>
          <h2>TradeWinds offices:</h2>

          <p>
            Please click on the office header for local staff contact details.
          </p>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              London
            </a>
            <br>
            <p>
              125 Wood Street, 7th Floor. London EC2V 7AN, UK
            </p>
            <p>
              Tel: +44 207 645 2300
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:julian.bray@tradewindsnews.com">
                      julian.bray@tradewindsnews.com
                    </a>
                  </td>
                  <td>Editor-in-Chief</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:paul.berrill@tradewindsnews.com">
                      paul.berrill@tradewindsnews.com
                    </a>
                  </td>
                  <td>Features Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:max.lin@tradewindsnews.com">
                      max.lin@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:lucy.hine@tradewindsnews.com">
                      lucy.hine@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:adam.corbett@tradewindsnews.com">
                      adam.corbett@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:geoff.garfield@tradewindsnews.com">
                      geoff.garfield@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:gary.dixon@tradewindsnews.com">
                      gary.dixon@tradewindsnews.com
                    </a>
                  </td>
                  <td>Deputy Editor Online</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:andy.pierce@tradewindsnews.com">
                      andy.pierce@tradewindsnews.com
                    </a>
                  </td>
                  <td>News Editor Online</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:ian.lewis@tradewindsnews.com">
                      ian.lewis@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:monica.alcalde@tradewindsnews.com">
                      monica.alcalde@tradewindsnews.com
                    </a>
                  </td>
                  <td>Chief Sub-Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:thomas.lovejoy@tradewindsnews.com">
                      thomas.lovejoy@tradewindsnews.com
                    </a>
                  </td>
                  <td>Deputy Chief Sub-Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:julian.stuart@tradewindsnews.com">
                      julian.stuart@tradewindsnews.com
                    </a>
                  </td>
                  <td>Sub-Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:andrew.hollis@tradewindsnews.com">
                      andrew.hollis@tradewindsnews.com
                    </a>
                  </td>
                  <td>Sub-Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:jon.spencer@tradewindsnews.com">
                      jon.spencer@tradewindsnews.com
                    </a>
                  </td>
                  <td>Managing Director</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:alan.odoherty@tradewindsnews.com">
                      alan.odoherty@tradewindsnews.com
                    </a>
                  </td>
                  <td>Marketing Communications Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:andy.read@tradewindsjobs.com">
                      andy.read@tradewindsnews.com
                    </a>
                  </td>
                  <td>Digital News Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:james.waters@tradewindsnews.com">
                      james.waters@tradewindsnews.com
                    </a>
                  </td>
                  <td>Senior Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:dimitris.karailias@tradewindsnews.com">
                      dimitris.karailias@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:james.swift@tradewindsnews.com">
                      james.swift@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:francesca.sharpe@nhst.com">
                      francesca.sharpe@nhst.com
                    </a>
                  </td>
                  <td>Office Manager (On leave)</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:paul.cook@tradewindsnews.com">
                      paul.cook@tradewindsnews.com
                    </a>
                  </td>
                  <td>Office Manager/Account Manager Credit Control</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:gregory.whitehead@tradewindsnews.com">
                      gregory.whitehead@tradewindsnews.com
                    </a>
                  </td>
                  <td>Events: Head of Portfolio Sales</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:carl.fleischer@tradewindsnews.com">
                      carl.fleischer@tradewindsnews.com
                    </a>
                  </td>
                  <td>Graphic Designer</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:pranjal.borkotoky@tradewindsnews.com">
                      pranjal.borkotoky@tradewindsnews.com
                    </a>
                  </td>
                  <td>Conference Director</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:holly.birkett@tradewindsnews.com">
                      holly.birkett@tradewindsnews.com
                    </a>
                  </td>
                  <td>London Correspondent</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Oslo
            </a>
            <br>
            <p>
              Street: Christian Krohgs gate 16, 0186 Oslo, Norway<br>
              Post: PO Box 1182 Sentrum, 0107 Oslo, Norway
            </p>
            <p>
              Tel: +47 2200 1200
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:trond.lillestolen@tradewindsnews.com">
                      trond.lillestolen@tradewindsnews.com
                    </a>
                  </td>
                  <td>Founding Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:hege.hansen@tradewindsnews.com">
                      hege.hansen@tradewindsnews.com
                    </a>
                  </td>
                  <td>Operations Director</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:anett.gustad@tradewindsnews.com">
                      anett.gustad@tradewindsnews.com
                    </a>
                  </td>
                  <td>Subscription Operations Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:ole.petter.wiik@tradewindsnews.com">
                      ole.petter.wiik@tradewindsnews.com
                    </a>
                  </td>
                  <td>Senior Account Mngr Advertising</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Stamford
            </a>
            <br>
            <p>
              1010 Washington Blvd. 2nd Floor. Stamford, CT 06901. USA
            </p>
            <p>
              Tel: +1 203 324 2994
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:joe.brady@tradewindsnews.com">
                      joe.brady@tradewindsnews.com
                    </a>
                  </td>
                  <td>Americas Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:eric.martin@tradewindsnews.com">
                      eric.martin@tradewindsnews.com
                    </a>
                  </td>
                  <td>News Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:michael.juliano@tradewindsnews.com">
                      michael.juliano@tradewindsnews.com
                    </a>
                  </td>
                  <td>Americas Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:matt.coyne@tradewindsnews.com">
                      matt.coyne@tradewindsnews.com
                    </a>
                  </td>
                  <td>Americas Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:lee.denslow@tradewindsnews.com">
                      lee.denslow@tradewindsnews.com
                    </a>
                  </td>
                  <td>Vice President Sales</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:aaron.kelley@tradewindsnews.com">
                      aaron.kelley@tradewindsnews.com
                    </a>
                  </td>
                  <td>Content Marketing Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:nick.skowronski@tradewindsnews.com">
                      nick.skowronski@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:justin.miller@tradewindsnews.com">
                      justin.miller@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:erin.moore@tradewindsnews.com">
                      erin.moore@tradewindsnews.com
                    </a>
                  </td>
                  <td>Vice President, Global Credit Control</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Singapore
            </a>
            <br>
            <p>
              20 Upper Circular Road, The Riverwalk 04-04, Singapore 058416
            </p>
            <p>
              Tel: +86 21 6329 6381
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:irene.ang@tradewindsnews.com">
                      irene.ang@tradewindsnews.com
                    </a>
                  </td>
                  <td>Asia Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:jonathan.boonzaier@tradewindsnews.com">
                      jonathan.boonzaier@tradewindsnews.com
                    </a>
                  </td>
                  <td>Asia Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:dale.wainwright@tradewindsnews.com">
                      dale.wainwright@tradewindsnews.com
                    </a>
                  </td>
                  <td>Asia Correspondent Online</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:leonard.stornes@tradewindsnews.com">
                      leonard.stornes@tradewindsnews.com
                    </a>
                  </td>
                  <td>MD, DN Media Group (Asia)</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:amber.chang@tradewindsnews.com">
                      amber.chang@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Advertising</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:morris.ng@tradewindsnews.com">
                      morris.ng@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Advertising</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:shraddha.menon@tradewindsnews.com">
                      shraddha.menon@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:nivea.ortizo@tradewindsnews.com">
                      nivea.ortizo@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Circulation</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:evan.kng@tradewindsnews.com">
                      evan.kng@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager, Credit Control</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Vishnumahanti.Deepthi@nhst.no">
                      vishnumahanti.deepthi@nhst.com
                    </a>
                  </td>
                  <td>Office Manager/HR</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Bergen
            </a>
            <br>
            <p>
              Sandbrogaten 5, 5th Floor, 5003 Bergen, Norway
            </p>
            <p>
              Tel: + 47 9325 6010
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:eva.hessen@tradewindsnews.com">
                      eva.hessen@tradewindsnews.com
                    </a>
                  </td>
                  <td>Advertising Director Europe &amp; Africa</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:geir.forde@tradewindsnews.com">
                      geir.forde@tradewindsnews.com
                    </a>
                  </td>
                  <td>Content Sales Director</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Athens
            </a>
            <br>
            <p>
              Michalakopoulou 29, GR 115 28 Athens, Greece
            </p>
            <p>
              Tel: + 30 210 724 5541
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:harry.papachristou@tradewindsnews.com">
                      harry.papachristou@tradewindsnews.com
                    </a>
                  </td>
                  <td>Athens Correspondent</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:marina.panopoulou@tradewindsnews.com">
                      marina.panopoulou@tradewindsnews.com
                    </a>
                  </td>
                  <td>Account Manager Advertising</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Shanghai
            </a>
            <br>
            <p>
              K11, Room 2613, 26th Floor, No. 300 Huaihai Middle Road, Shanghai 200021 China
            </p>
            <p>
              Tel: +65 3165 0985
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:sally.yip@tradewindsnews.com">
                      sally.yip@tradewindsnews.com
                    </a>
                  </td>
                  <td>Senior Account Manager Advertising</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            _________________________<br>
            <span class="font-weight-bold">Headquarters:</span><br>
            <br>
            TradeWinds AS<br>
            Christian Krohgsgt 16<br>
            PO Box 1182 Sentrum<br>
            0107 Oslo<br>
            Norway<br>
            <br>
            Company registration number: 937 593 821
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'contactus-page',
  extends: Page
};
</script>
